import React, { useState } from 'react';
import logo from './logo.svg';
import SceneContainer from './Scene';
import { Details, Project } from './Details';
import './App.css';

let cursor_index = 0




/* Graphics stuff */
// class Square {
//   width: number;
//   height: number;
//   points: Point[];
//   dist: number;

//   constructor(z: number, canvas: HTMLCanvasElement) {
//     this.width = canvas.width/2;

//     if(canvas.height < 200){
//       this.width = 200;
//     };

//     this.height = canvas.height;
//     z = z || 0;

//     this.points = [
//     new Point({
//         x: (canvas.width / 2) - this.width,
//         y: (canvas.height / 2) - this.height,
//         z: z,
//     }, canvas),
//     new Point({
//         x: (canvas.width / 2) + this.width,
//         y: (canvas.height / 2) - this.height,
//         z: z
//     }, canvas),
//     new Point({
//         x: (canvas.width / 2) + this.width,
//         y: (canvas.height / 2) + this.height,
//         z: z
//     }, canvas),
//     new Point({
//         x: (canvas.width / 2) - this.width,
//         y: (canvas.height / 2) + this.height,
//         z: z
//     }, canvas)];
//     this.dist = 0;
//   }

//   update() {
//     for (var p = 0; p < this.points.length; p++) {
//         this.points[p].rotateZ(0.001);
//         this.points[p].z -= 3;
//         if (this.points[p].z < -300) {
//             this.points[p].z = 2700;
//         }
//         this.points[p].map2D();
//     }
//   }

//     render(ctx: CanvasRenderingContext2D) {
//       ctx.beginPath();
//       ctx.moveTo(this.points[0].xPos, this.points[0].yPos);
//       for (var p = 1; p < this.points.length; p++) {
//           if (this.points[p].z > -(focal - 50)) {
//               ctx.lineTo(this.points[p].xPos, this.points[p].yPos);
//           }
//       }

//       ctx.closePath();
//       ctx.stroke();

//       this.dist = this.points[this.points.length - 1].z;

//   };
// }


// class Point {
//   public x: number
//   public y: number
//   public z: number
//   cY: number;
//   cX: number;
//   cZ: number;
//   xPos: number;
//   yPos: number;
//   focal: number;
//     constructor(pos: {x: number, y: number, z: number}, focal: number, canvas: HTMLCanvasElement) {
//       this.x = pos.x - (canvas as any).width / 2 || 0;
//       this.y = pos.y - (canvas as any).height / 2 || 0;
//       this.z = pos.z || 0;

//       this.cX = 0;
//       this.cY = 0;
//       this.cZ = 0;

//       this.xPos = 0;
//       this.yPos = 0;
//       this.focal = focal;
//       this.map2D();
//     }

//     map2D() {
//       var scaleX = this.focal / (this.focal + this.z + this.cZ),
//           scaleY = this.focal / (this.focal + this.z + this.cZ);

//       this.xPos = vpx + (this.cX + this.x) * scaleX;
//       this.yPos = vpy + (this.cY + this.y) * scaleY;
//   };

//   rotateZ(angleZ: number) {
//       var cosZ = Math.cos(angleZ),
//           sinZ = Math.sin(angleZ),
//           x1 = this.x * cosZ - this.y * sinZ,
//           y1 = this.y * cosZ + this.x * sinZ;

//       this.x = x1;
//       this.y = y1;
//   }
// }









// function init() {
//   var canvas: any = document.querySelector(".hacker-3d-shiz"),
//     ctx = canvas.getContext("2d");

//     canvas.width = (window.innerWidth/3)*2;
//     canvas.height = window.innerHeight / 3;

//     window.addEventListener('resize', function(){
//       canvas.width = (window.innerWidth/3)*2;
//       canvas.height = window.innerHeight / 3;


//       focal = canvas.width / 2;
//       vpx = canvas.width / 2;
//       vpy = canvas.height / 2;
//   });

//       // Init graphics stuff
//     var squares = [],
//     focal = canvas.width / 2,
//     vpx = canvas.width / 2,
//     vpy = canvas.height / 2,
//     barVals = [],
//     sineVal = 0;

//     setTimeout(function(){   
//       canvas.width = (window.innerWidth/3)*2;
//       canvas.height = window.innerHeight / 3;

//       focal = canvas.width / 2;
//       vpx = canvas.width / 2;
//       vpy = canvas.height / 2;

//       for (var i = 0; i < 15; i++) {
//           squares.push(new Square(-300 + (i * 200), canvas));
//       }


//       var render = function () {
//         ctx.clearRect(0, 0, canvas.width, canvas.height);





//         requestAnimationFrame(render);
//       }

//       render();
// }, 200);


// }

function App() {

  React.useEffect(() => {
    // init()
    setInterval(() => {
      console.log(cursor_index)
      for (let i = 0; i < 3; i++) {
        if (i != cursor_index) {
          document.body.classList.remove('cursor' + i);
        } else {
          document.body.classList.add('cursor' + cursor_index);
        }
      }
      cursor_index = (cursor_index + 1) % 3
    }, 200);
    return () => {
      console.log('MyComponent onUnmount');
    };
  }, []);


  enum item_type {
    GAME,
    EXPERIMENT,
    WEBSITE,
    APP
  }

  const items: Project[] = [
    {
      src: "terra_sperma.mp4",
      title: "TERRA_SPERMA",
      description: "Set in a wasteland far from Earth, you play as Globe, a lonely human exploring the depths of depravity",
      fullDescription: "Set in a wasteland far from Earth, you play as Globe, a lonely human exploring the depths of depravity. Made in GODOT with GLSL shaders, lots of pixel art, procedural animation, and synth music. Out on Steam soon!",
      folder: 'terra_sperma',
      hasTitleImage: true,
      largeTiles: false,
      tiles: [
        'bigguy.mp4',
        'birth.mp4',
        'blackhole.mp4',
        'ladybug.mp4',
        'outside.mp4',
        'outside2.mp4',
        'theabstract.mp4',
        'theythem69.mp4',
        'wasp.mp4',
        'slime.png',
        'ddddd.png',
        'head.png',
        'a.png'

      ]
   
    },

    {
      src: "simbiosis.mp4",
      title: "simbiosis",
      description: "Watch procedurally generated plants grow in realtime and manage light, humidity, water etc",
      fullDescription: "Watch procedurally generated plants grow in realtime and manage light, humidity, water etc. Made in Unity, uses a plant-generation algorithm that can create many different kinds of shapes and sizes of plants without any prebuilt assets. Out on Steam soon!",
      folder: 'simbiosis',
      hasTitleImage: true,
      largeTiles: true,
      tiles: [
        'idk.mp4',
        'a.png',
        'b.png',
        'c.png',
        'anthill.png'

      ]
    },

    {
      src: "infinite_toy_box.mp4",
      title: "Infinite Toy Box",
      description: "Infinitely generated world full of lush grass and cute meadows, Made in ThreeJS",
      link: "https://begaydocrime.dev/"
    },

    {
      src: "rave.mp4",
      title: "party projection",
      description: "Projection made for a dance party"
    },


    {
      src: "dlrt.gif",
      title: "DLRT app",
      description: "An app that let businesses present deals geographically through push notifications"
    },

    {
      src: "tetris3d.png",
      title: "3D Tetris",
      description: "Play Tetris in the 3rd dimension"
    },

    {
      src: "policebrutalitypdx.png",
      title: "Police Brutality PDX .com",
      description: "Tracked police brutality incidents during 2020-2021"
    },

    {
      src: "anthill.png",
      title: "Ant Hill",
      description: "Simple game where you spawn ants that eat into the environment"
    },

    {
      src: "anacoda.webp",
      title: "ANACODA",
      description: "Play as a gargantuan worm that destroys buildings as you eat people against the clock"
    },

    {
      src: "oil_planet.gif",
      title: "Octree Planet",
      description: "Experimented with octrees and dynamic terrain generation on the GPU and OpenCL"
    },

    {
      src: "cell_sim.png",
      title: "Cell Simulator",
      description: "Simple organic cell simulator made with OpenGL"

    },

    {
      src: "bernard.mp4",
      title: "NES Game",
      description: "Play as a politician trying to revolutionize politics on the original NES"
    }
  ]

  const [details, setDetails] = useState(null);

  return (
    <div className="App">

      <body className='body'>
        {
          details ?
            <Details project={details!} close={()=> setDetails(null)}></Details>
            :
            <></>
        }
        <div className="column1">
          {/* <h1><code>GAMES & PROJECTS</code></h1> */}
          <div className="flex-container">
            {items.map(item => (
              <div className={`item${item.folder || item.link ? '' : '-d' }`} onClick={() => {if (item.link) (window as any).open(item.link, '_blank').focus(); else if (item.folder) setDetails(item as any)}}>
                <div className='circle-container'>
                  <svg className="circle" height="100" width="100">
                    <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fillOpacity="100" />
                  </svg>
                </div>
                <div className='content'>
                  {'gif png webp'.includes(item.src.split('.')[1]) ?
                    (
                      <img src={`/videos/${item.src}`} className='gif' />
                    ) :
                    (
                      <video width="100%" autoPlay muted loop playsInline>
                        <source src={`/videos/${item.src}`} type={`video/${item.src.split('.')[1]}`} />
                        Sorry, your browser doesn't support videos.
                      </video>
                    )
                  }
                  <div className="title"><code>{item.title}</code></div>
                  <div className='description'><code>{item.description}</code></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="column2">
          <div className="contents-column2">
            <header className="App-header">
              <img className="pfp" src="/pfp3.jpg" alt="Profile Picture" />
              {/* <h2>Portfolio</h2> */}
              <p>
                Washer Faynzilberg
              </p>
            </header>
            <p>
              Hi I'm Washer - I make games and work at tech companies.
              <br></br>

              <br></br>
              Check out some of my projects :)
              <br></br>
              <br></br>

            </p>
            <div className='contact'>
              Contact me <a href="mailto:fluidwasher@duck.com"><span>fluidwasher@duck.com</span></a>
              <br></br>
              <br></br>
              <a className='link' href="https://www.linkedin.com/in/washer-faynzilberg-62266995/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" className="mercado-match" width="32" height="32" focusable="false">
                  <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                </svg>
              </a>
              <a className='link' href="https://github.com/fishclaws" target="_blank">
                <svg height="32" fill="currentColor" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="32" data-view-component="true" className="octicon octicon-mark-github">
                  <path d="M8 0c4.42 0 8 3.58 8 8a8.013 8.013 0 0 1-5.45 7.59c-.4.08-.55-.17-.55-.38 0-.27.01-1.13.01-2.2 0-.75-.25-1.23-.54-1.48 1.78-.2 3.65-.88 3.65-3.95 0-.88-.31-1.59-.82-2.15.08-.2.36-1.02-.08-2.12 0 0-.67-.22-2.2.82-.64-.18-1.32-.27-2-.27-.68 0-1.36.09-2 .27-1.53-1.03-2.2-.82-2.2-.82-.44 1.1-.16 1.92-.08 2.12-.51.56-.82 1.28-.82 2.15 0 3.06 1.86 3.75 3.64 3.95-.23.2-.44.55-.51 1.07-.46.21-1.61.55-2.33-.66-.15-.24-.6-.83-1.23-.82-.67.01-.27.38.01.53.34.19.73.9.82 1.13.16.45.68 1.31 2.69.94 0 .67.01 1.3.01 1.49 0 .21-.15.45-.55.38A7.995 7.995 0 0 1 0 8c0-4.42 3.58-8 8-8Z"></path>
                </svg>
              </a>
            </div>
            {/* <SceneContainer></SceneContainer> */}

          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
