import React from 'react';
import './App.css';

export interface Project {
    title: string,
    description: string,
    fullDescription?: string,
    src: string,
    tiles?: string[],
    folder?: string,
    hasTitleImage?: boolean
    largeTiles?: boolean
    link?: string
}

function stopPropagation(e: any){
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

export function Details(props: { project: Project, close: () => void }): JSX.Element {
    return (
        <div className='background' onClick={props.close}>
            <div className='modal' onClick={stopPropagation}>
                <div className='X'  onClick={props.close}></div>
                <div>
                    {
                        props.project.hasTitleImage ?
                            <img className="title-image" src={`videos/${props.project.folder}/title.png`}></img>
                            :
                            <h2>{props.project.title}</h2>
                    }
                    <div className='full-description'><code>{props.project.fullDescription}</code></div>
                </div>
                <div className="details-container">

                    {
                        props.project.tiles ?
                            props.project.tiles.map(tile => (
                                <div className={`tile${props.project.largeTiles? `-large` : ''}`}>
                                    {'gif png webp'.includes(tile.split('.')[1]) ?
                                        (
                                            <img src={`/videos/${props.project.folder}/${tile}`} className={`gif`} />
                                        ) :
                                        (
                                            <video width="100%" autoPlay muted loop playsInline>
                                                <source src={`/videos/${props.project.folder}/${tile}`.replace(".", "-half.")} type={`video/${tile.split('.')[1]}`} />
                                                Sorry, your browser doesn't support videos.
                                            </video>
                                        )
                                    }
                                </div>
                            )) : (
                                <div></div>
                            )
                    }
                </div>
            </div>
        </div>
    );
}

